const Footer = () => {
  return (
   <div>
         {/*  footer Part Start  */}
    <footer id="footer_part">
        {/*Footer Bottom start*/}
        <div id="footet_bottom">
        <div class="container-fluid px_fluid">
                <div class="footer_main">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="footer_left">
                                <ul>
                                    <li>Copyright © 2021 | <a href="#">ICBS</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="footer_right text-md-right">
                                {/* <ul>
                                    <li>Design by <a href="https://themeforest.net/user/prothemeslab" target="_blank">Prothemelab</a></li>
                                    <li>Only On <a href="#">Envato Market</a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    {/*  Footer Part End  */}

    {/*  Bact to Top button start */}
    <div id="backtotop">
        <i class="fa fa-arrow-up backtotop_btn"></i>
    </div>
   </div>
  );
};
export default Footer;
