import React, { useState, useEffect } from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2 className="title">
                Terms <span>&</span> Conditions
                </h2>
                <h4>Any participation in this service will constitute acceptance of this agreement.</h4>
                <p>
                The school students are not allowed to use their chat service or their school account for the sake of any type of marketing under any conditions for any service or idea outside the scope of their school.</p>
            <p>
            This site, logos and all the videos accepted to be published over it are considered as an ownership for ICBS company for educational services and the use of any material over icbschools.com websites requires a copyright from the company otherwise this will be considered as an abuse for company copyrights.
            </p>
            <p>
            The company has full right to use students’ work either for marketing purposes of for any other purpose that will bring profits for the company.
            </p>
            <p>
            The students in school section are not allowed to share the syllabus videos or sessions presented by the company outside the company website and this considered as an abuse for company copyrights. 
            </p>
            <p>
            No pornography or discrimination or religions abusing videos are allowed on any section inside the website under any condition, and such acts put you under the site penalties.
            </p>
            <ul className="page_list">
              <h5>
                
              </h5>
                  <li>
                    <span>The site general penalties are:-</span>
                  </li>
                  <li>
                   1- Preventing the user from chat for a certain period of time.
                  </li>
                  <li>
                    2- Preventing the user from uploading option for a certain period of time.
                  </li>
                  <li>
                    3- Lock the user account for a certain period with or without financial penalty.
                  </li>
                  <li>
                    4- Lock the user account permanently with no refund if the user provides fake info about him/her self during establishing his account.
                  </li>
                </ul>
                <p>
                On locking the user account permanently the user can contact the administration board for the website requesting revising his case through, Mobile:-<a href="callto:+201023113710">+201023113710</a>, E-mail:-  <a href="mailto:icbschools.contact@gmail.com">
                        icbschools.contact@gmail.com
                      </a>
                </p>
                <p>
                For school users you must beware of your category and its financials deals and how the permitted period before changing your plan to be late category and understanding the pricing criteria very well.
                </p>
                <p>
                If the terms and conditions changes for our website your account will be freeze till you accept the new terms and conditions.
                </p>
                <p>
                You can join ICBS schools currently if you were from grade 7 to 12 from any legal country assigned by the website as a legal country all over the world and your account after that will be active forever.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}
    </div>
  );
};
export default Description;
