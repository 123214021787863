import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
const Login = ({ authenticate }) => {
  const { token } = useSelector((state) => ({
    token: state.Auth.token,
  }));
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userLoged, setuserLoged] = useState(false);
  const [userName, setuserName] = useState("");

  const [message, setErroeMesage] = useState(false);

  const [ForgetPass, setForgetPass] = useState(false);

  const [Emailmesage, setEmailmesage] = useState(null);
  const [done, setdone] = useState(false);
  
  const handleSubmit = (event) => {
    setErroeMesage(false);
    event.preventDefault();
    const LogindUser = {
      email: email,

      password: password,
    };
    axios
      .post(apiEndPointsConfig.newApiDomain + "login", LogindUser)
      .then((response) => {
        console.log(response.data);
        authenticate(response.data);
        setIsLoading(false);
        setuserLoged(true);
        setuserName(
          response.data.data.first_name + " " + response.data.data.last_name
        );
        window.location.href = "/";
      })
      .catch((error) => {
        setIsLoading(false);
        setErroeMesage(true);
      });
    //
    setIsLoading(true);
  };
  const handleSubmitEmail=(e)=>{
    setIsLoading(true)
    e.preventDefault();
    sendResetPassword()
    
};
const sendResetPassword = () => {
   const userData={
        "email":email,
}
    axios.post(apiEndPointsConfig.newApiDomain + "forget_password", userData)
    .then(response => {
      setIsLoading(false)
        setdone(true)

    })
    .catch(err => {
        setEmailmesage(err.response.data.error)
        setIsLoading(false)
    });
}

let location = useLocation();
  useEffect(() => {
    console.log(token);
    if (token) {
      window.location.href = "/";
    }
  }, [token]);
  return (
    <div className="login-page row">
      {userLoged ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message={`Welcome ${userName}`}
          type="success"
        />
      ) : (
        ""
      )}
      <div className="login_container  col-md-7">
        <div className="login-form">
          {
            !ForgetPass?
         
          <div>
               <div className="login-head">
            <h2 className="login_title">Login</h2>
          </div>

          <form className="row" onSubmit={handleSubmit}>
          {location.search?(
               <div className=" form-group col-md-12">
               <div className="succses_mesage ">
                 <p>Password changed successfully, you can login now with your new password </p>
               </div>
             </div>
           ) : (
             ""
           )
            }
            {message ? (
              <div className=" form-group col-md-12">
                <div className="error_mesage ">
                  <p>Invalid email or password</p>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-group col-md-12">
              <label htmlFor="formEmail"> Email </label>
              <input
                type="email"
                className="form-control"
                id="formEmail"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="formPassword"> Password </label>
              <input
                type="password"
                className="form-control"
                id="formPassword"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="text-right form-group col-md-12 d-flex justify-content-between ">
              <Link onClick={()=>setForgetPass(true)}>
                <span className="link forgot-password">Forgot Password</span>
              </Link>
              <button
                type="submit"
                disabled={isLoading}
                className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                  isLoading ? "is-loding" : ""
                }`}
              >
                {isLoading ? (
                  <ClipLoader size={24} color={"#fff"} loading={true} />
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>

          </div>
          :
          <div>
               <div className="login-head">
            <h2 className="login_title">Forgot your password</h2>
            <p>
              Don't worry! Just fill in your email and we'll send you a link to reset your pasword.
            </p>
          </div>

          <form className="row" onSubmit={handleSubmitEmail}>
            {Emailmesage ? (
              <div className=" form-group col-md-12">
                <div className="error_mesage ">
                  <p>{Emailmesage}</p>
                </div>
              </div>
            ) : (
              ""
            )}
            {done?(
               <div className=" form-group col-md-12">
               <div className="succses_mesage ">
                 <p>we've sent you an email with a link to reset your password, please check your e-mail</p>
               </div>
             </div>
           ) : (
             ""
           )
            }
            <div className="form-group col-md-12">
              <label htmlFor="formEmail"> Email </label>
              <input
                type="email"
                className="form-control"
                id="formEmail"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          

            <div className="text-right form-group col-md-12 d-flex justify-content-between ">
              <Link onClick={()=>setForgetPass(false)}>
                <span className="link forgot-password">Back to login</span>
              </Link>
              <button
                type="submit"
                disabled={isLoading}
                className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                  isLoading ? "is-loding" : ""
                }`}
              >
                {isLoading ? (
                  <ClipLoader size={24} color={"#fff"} loading={true} />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>

          </div>
}
          <div className="login-lower text-center">
            <div className="links">
              <Link className="link_footer"> Terms </Link>|
              <Link className="link_footer"> Privacy </Link>
            </div>
            <p> Copyright © 2021 | ICBS </p>
          </div>
        </div>
      </div>
      <div
        className="image_container col-md-5 col-12"
        style={{
          backgroundImage: `url(assets/images/pexels-fauxels-3184287.jpg)`,
        }}
      >
        <div className="overlay">
          <Link
            onClick={() => (window.location.href = "/")}
            className="btn-back"
          >
            <img src="assets/images/close.svg" alt="close" />
          </Link>
          <div className="logo_data">
            <a class="navbar-brand main_logo" >
              <img src="assets/images/logo.png" alt="LOGO" />
              <h3>ICBS</h3>
            </a>
            <p className="mb-2">Here, you will find your Bright future.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state, { authenticate })(Login);
