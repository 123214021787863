import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, connect } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import { deauthenticate } from "../Store/actions/Auth";

const Navbar = ({ deauthenticate }) => {
  const { token, gender, userName, UserInfo } = useSelector((state) => ({
    token: state.Auth.token,
    gender: state.Auth.gender,
    userName: state.Auth.first_name + " " + state.Auth.last_name,
    UserInfo: state.Auth,
  }));
  return (
    <header id="header_part">
      <nav class="navbar navbar-expand-lg navbar-default my_nav">
        <div class="container-fluid px_fluid">
          {/* Brand and toggle get grouped for better mobile display */}
          <a class="navbar-brand main_logo" href="/">
            <img src="assets/images/logo.png" alt="LOGO" />
            <h3>ICBS</h3>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#nav_list"
            aria-controls="nav_list"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg class="bi bi-list" viewBox="0 0 16 16" fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          {/* Collect the nav links, forms, and other content for toggling */}
          <div class="collapse navbar-collapse" id="nav_list">
            <ul class="nav navbar-nav ml-auto">
              <li >
                <a href="/">Home</a>
              </li>
              {/* <li>
                <a href="#about_part">About</a>
              </li> */}
              {!token && (
                <li>
                  <a>
                    <DropdownButton id="dropdown-basic-button" title="school">
                      <Dropdown.Item href="/login">Login</Dropdown.Item>
                      <Dropdown.Item href="/registration">
                        Registration
                      </Dropdown.Item>
                    </DropdownButton>
                  </a>
                </li>
              )}
              {token && (
                <li>
                  <a onClick={() => (window.location.href = "/payment")}>
                    Payment
                  </a>
                </li>
              )}
              {/* <li>
                <a>categories panorama</a>
              </li> */}  
              <li>
                  <a onClick={() => (window.location.href = "/future-targets")}>
                  future targets
                  </a>
                </li>
              <li>
                <a>
                  <DropdownButton id="dropdown-basic-button" title="Business">
                    <Dropdown.Item href="/business-description">
                      business description
                    </Dropdown.Item>
                    <Dropdown.Item href="/service-pricing">
                      service pricing
                    </Dropdown.Item>
                    <Dropdown.Item href="/privacy-policy">
                      Privacy policy
                    </Dropdown.Item>

                    <Dropdown.Item href="/refund-and-cancellation">
                      Refund & Cancellation policy
                    </Dropdown.Item>
                    <Dropdown.Item href="/delivery-policy">
                      Delivery policy
                    </Dropdown.Item>
                    <Dropdown.Item href="/terms-and-conditions">
                      Terms & Conditions
                    </Dropdown.Item>
                  </DropdownButton>
                </a>
              </li>
            
              
              {/* <li>
                <a>contact</a>
              </li> */}
              {token && (
                <li className="nav-item">
                  <Dropdown alignRight className="dropdown-login-user">
                    <Dropdown.Toggle variant="success" id="dropdown-user">
                      <div className="avatar">
                        {UserInfo.avatar ? (
                          <img
                            src={UserInfo.avatar}
                            alt=""
                            className="rounded"
                          />
                        ) : gender == "male" ? (
                          <img src="assets/images/profile.svg" alt="" />
                        ) : (
                          <img src="assets/images/profileF.svg" alt="" />
                        )}
                      </div>
                      <span className="text-capitalize mx-3 d-none d-sm-inline-block">
                        {userName}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item as="div" className="p-0">
                        <Link
                          onClick={() => (window.location.href = "/profile")}
                          className="d-flex item align-items-center"
                        >
                          <div className="avatar">
                            {UserInfo.avatar ? (
                              <img
                                src={UserInfo.avatar}
                                alt=""
                                className="rounded"
                              />
                            ) : UserInfo.gender == "male" ? (
                              <img src="assets/images/profile.svg" alt="" />
                            ) : (
                              <img src="assets/images/profileF.svg" alt="" />
                            )}
                          </div>
                          <h6 className="text-capitalize mx-3  d-sm-inline-block">
                            Profile
                          </h6>
                        </Link>
                      </Dropdown.Item>
                      {UserInfo.module && UserInfo.category && UserInfo.category.is_late == 0 && (
                        <Dropdown.Item as="div" className="p-0">
                          <Link
                            onClick={() =>
                              (window.location.href = "/payment-history")
                            }
                          >
                            <div className="d-flex item align-items-center">
                              <div className="avatar">
                                <img src="assets/images/salary.svg" alt="" />
                              </div>
                              <h6 className="text-capitalize mx-3  d-sm-inline-block">
                                Payment History
                              </h6>
                            </div>
                          </Link>
                        </Dropdown.Item>
                      )}
                      {UserInfo.module && UserInfo.category && UserInfo.category.is_late == 0 && (
                        <Dropdown.Item as="div" className="p-0">
                          <Link
                            onClick={() =>
                              (window.location.href = "/referral-code-usage")
                            }
                          >
                            <div className="d-flex item align-items-center">
                              <div className="avatar">
                                <img
                                  src="assets/images/collaborate.svg"
                                  alt=""
                                />
                              </div>
                              <h6 className="text-capitalize mx-3  d-sm-inline-block">
                                Referals
                           
                              </h6>
                            </div>
                          </Link>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        as="div"
                        onClick={deauthenticate}
                        className="p-0"
                      >
                        <Link>
                          <div className="d-flex item align-items-center">
                            <div className="avatar">
                              <img src="assets/images/power.svg" alt="" />
                            </div>
                            <h6 className="text-capitalize mx-3  d-sm-inline-block">
                              Logout
                            </h6>
                          </div>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}{" "}
            </ul>
          </div>
          {/* /.navbar-collapse */}
        </div>
        {/* /.container */}
      </nav>
    </header>
  );
};
const mapStateToProps = (state) => ({ isAuthenticated: !!state.Auth.token });

export default connect(mapStateToProps, { deauthenticate })(Navbar);
