import React, { useState, useEffect } from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2 className="title">
                  Privacy <span>&</span> Policy
                </h2>
                <h4>
                  At ICBS, accessible from icbschools.com, one of our main
                  priorities is the privacy of our visitors. This Privacy Policy
                  document contains types of information that is collected and
                  recorded by ICBS and how we use it. If you have additional
                  questions or require more information about our Privacy
                  Policy, do not hesitate to contact us. This Privacy Policy
                  applies only to our online activities and is valid for
                  visitors to our website with regards to the information that
                  they shared and/or collect in icbschools.com. This policy is
                  not applicable to any information collected offline or via
                  channels other than this website.
                </h4>
                <h6 className="priv_title" >The Scope of this Privacy Policy</h6>
                <p>
                  This Privacy Policy is applicable worldwide and applies to our
                  services where We provide a link to this Privacy Policy,
                  unless otherwise stated or unless we provide a link to a
                  different policy. All services will contain a prominent link
                  to this Privacy Policy on or near the homepage of our site or
                  through a pop-up window on any mobile and/or other Internet
                  connected devices ("Wireless Devices") where possible. This
                  Privacy Policy applies to information that we collect online
                  through use of the Services. The Services may contain links to
                  Third Party Platforms. If You use Our Services on or through a
                  Third-Party Platform, including via any Wireless Devices, your
                  privacy will be subject only to the privacy policies and terms
                  of service of the Third-Party Platform and without any
                  liability on ICBS. We do not control and are not responsible
                  for the terms of service, privacy policies and/or practices of
                  any Third-Party Platform You may use to access the services,
                  and You should review the policies and agreements of such
                  Third-Party Platforms carefully.
                </p>
<h6 className="priv_title" >Public forums</h6>
<p>The Site may, from time to time, make chat rooms, message boards, news groups, or other public forums available to its users, whether by way of our own services or by way of the services or features of a third party. Any information that is disclosed in these areas becomes public information and you should exercise caution when using these areas and avoid posting any personal or sensitive information. If a separate login is required to use these areas, please be aware that you may need to also log out separately.
</p>
<h6 className="priv_title" >Changes to this Privacy Policy</h6>
<p>As our Site evolves, this Privacy Policy will need to evolve as well to cover new situations. To ensure that you are kept informed of changes, check this Privacy Policy periodically as we will update the date at the top of the document any time we make a change. If we make material changes to this Privacy Policy, we will post the revised policy on this page and will send a notice via email or our Site to all registered users of the Platform. Your continued use of our Site after any such changes constitutes your acceptance of the new Privacy Policy. If you do not agree to abide by these or any future version of the Privacy Policy, you do not have permission to use or access (or continue to use or access) our Site.
</p>
<h6 className="priv_title" >Information we collect
</h6>
<p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
When you register for an Account, we may ask for your contact information, including items such as name, address, email address, and telephone number.
</p>
<h6 className="priv_title" >hird Party Privacy Policies</h6>
<p>ICBS's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.

You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
</p>
<h6 className="priv_title" >How we use your information</h6>
<p>We use the information we collect in various ways, including to:

Provide, operate, and maintain our website

Improve, personalize, and expand our website

Understand and analyze how you use our website

Develop new products, services, features, and functionality

Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes

Send you emails

Find and prevent fraud</p>
<h6 className="priv_title" >Consent</h6>
<p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.

</p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
};
export default Description;
