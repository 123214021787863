import Routers from "./router";
import { makeStore } from './Store/index'
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { reauthenticate } from './Store/actions/Auth';
import { Provider } from "react-redux";
const initialState = {};
const store = makeStore(initialState, BrowserRouter);
store.dispatch(reauthenticate())

function App() {
  return (
    <div className="App">
        <Provider store={store}>
              <Routers />
          </Provider>

    </div>
  );
}

export default App;
