import { REGISTER, AUTHENTICATE, DEAUTHENTICATE, ERRORAUTHENTICATE, RESETAUTHENTICATE, USERISLOGOUT } from '../actionTypes';

const authReducer = (state =
    {
        token: null,
        first_name: null,
        last_name: null,
        gender: null,
        email: null,
        id: null,
        message: null,
        group_leader: null,
        team_leader: null,
        email: null,
        mobile: null,
        errorMessage: null,
        loginStatus: null,
        address: null,
        age: null,
        category: null,
        country: null,
        dream: null,
        educational_grade_year: null,
        educational_grade_type:null,
        module: null,
        referral_code: null,
        referrer_by: null,
        avatar:null,
        subscription: null,
        user_name: null,
       
    }, action) => {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                loginStatus: 'login',
                token: action.payload,
                mobile: action.mobile,
                gender: action.gender,
                first_name: action.first_name,
                last_name: action.last_name,
                email: action.email,
                id: action.id,
                address: action.address,
                age: action.age,
                category: action.category,
                country: action.country,
                dream: action.dream,
                educational_grade_year: action.educational_grade_year,
                educational_grade_type:action.educational_grade_type,
                module: action.module,
                referral_code: action.referral_code,
                referrer_by: action.referrer_by,
                avatar:action.avatar,
                subscription: action.subscription,
                user_name: action.user_name,
                
            };
        case USERISLOGOUT:
            return { ...state, loginStatus: 'logout' };
        case ERRORAUTHENTICATE:
            return { ...state, errorMessage: action.errorMessage };
        case RESETAUTHENTICATE:
            return { ...state, errorMessage: '' };

        case DEAUTHENTICATE:
            return { token: null, message: action.message, mobile_verfied: action.mobile_verfied, mail_verfied: action.mail_verfied, email: action.email, mobile: action.mobile };

        case REGISTER:
            return { ...state, registeration_token: action.payload, corporate_id: action.corporate_id, first_name: action.first_name, last_name: action.last_name, gender: action.gender, id: action.id, email: action.email };

        default:
            return state;
    }
};

export default authReducer;