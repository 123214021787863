import React, { useState, useEffect } from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2 className="title">
                <span>service</span> pricing
                </h2>
                <p>
                The study fees differ in our school from student to another based on his category, achievements, country and social rank.
                </p>
                <ul className="page_list">
                  <li>
                    <span>Certificate pricing:-</span>  Ranges from 50 EGP to 7420 EGP.
                  </li>
                  <li>
                    <span>Monthly fees:-</span>  Ranges from 0 EGP to 1600 EGP.
                  </li>
                  <li>
                    <span>Honoring party request fees:-</span>  RRanges from 0 EGP to 3710 EGP.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}
    </div>
  );
};
export default Description;
