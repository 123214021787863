import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Modal } from "react-bootstrap";
const Payment = ({ authenticate }) => {
  const { token, UserInfo } = useSelector((state) => ({
    UserInfo: state.Auth,
    token: state.Auth.token,
  }));

  const [certifcate_fees, setcertifcate_fees] = useState(0);
  const [ShowFees, setShowFees] = useState(false);
  const [FileIsUplode, setFileIsUplode] = useState(false);
  const [type_uplode, setType] = useState("");
  const [monthly_count, setmonthly_count] = useState(null);
  
  const Getpayment_certficate_result = () => {
    axios
      .get(apiEndPointsConfig.newApiDomain + "payment_certficate_result", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setcertifcate_fees(response.data.data.certifcate_fees);
        
      })
      .catch((err) => {});
  };
  const Getpayment_subscription_result = (monthly_count) => {
    setmonthly_count(monthly_count)
    axios
      .post(
        apiEndPointsConfig.newApiDomain + "monthly_subscription_result",
        monthly_count,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setcertifcate_fees(response.data.data.monthly_count_fees);
        setShowFees(true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      if (window.location.pathname == "/payment-certficate") {
        Getpayment_certficate_result();
      }
    }
  }, [token]);
  return (
    <div className="page">
     
      
     
      <div class="container">
        <div className="M_t_17">
          {window.location.pathname == "/payment-certficate" ? (
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                
                      <div className="d-flex flex-column align-items-center text-center">
                        <h3 className="price">{certifcate_fees} EGP</h3>

                        <div className="mt-3">
                          <h4>Need to be payed</h4>
                          <button
                            onClick={() => 
                             window.location.href="/payment-certficate-method"
                         }
                            type="submit"
                            className={`btn button_payment`}
                          >
                            Pay Now
                          </button>
                        </div>
                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          ) : window.location.pathname == "/payment-subscription" ? (
            !ShowFees ? (
              <div className="row">
                <div className="col-md-12">
                  <h3>Select Months Number To Subscribe</h3>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <a  onClick={() =>
                          Getpayment_subscription_result({
                            monthly_count: 1,
                          })
                        }
                      >
                        <div className="d-flex flex-column align-items-center text-center">
                          <h3 className="price">1</h3>

                          <div className="mt-3">
                            <h4>Month</h4>
                          </div>
                        </div>

                      </a>
                     
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <a
                        onClick={() =>
                          Getpayment_subscription_result({
                            monthly_count: 3,
                          })
                        }
                      >
                        <div className="d-flex flex-column align-items-center text-center">
                          <h3 className="price">3</h3>

                          <div className="mt-3">
                            <h4>Months</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <a
                        onClick={() =>
                          Getpayment_subscription_result({
                            monthly_count: 6,
                          })
                        }
                      >
                        <div className="d-flex flex-column align-items-center text-center">
                          <h3 className="price">6</h3>

                          <div className="mt-3">
                            <h4>Months</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <a
                        onClick={() =>
                          Getpayment_subscription_result({
                            monthly_count: 12,
                          })
                        }
                      >
                        <div className="d-flex flex-column align-items-center text-center">
                          <h3 className="price">1</h3>

                          <div className="mt-3">
                            <h4>Year</h4>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                  
                        <div className="d-flex flex-column align-items-center text-center">
                          <h3 className="price">{certifcate_fees} EGP</h3>

                          <div className="mt-3">
                            <h4>Need to be payed</h4>
                            <button
                              onClick={() => {
                                window.location.href=`/payment-subscription-method?months=${monthly_count.monthly_count}`

                              }}
                              type="submit"
                              className={`btn button_payment`}
                            >
                              Pay Now
                            </button>
                          </div>
                        </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
