import React from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div className="container">
          <div className="row flex-md-row-reverse">
            <div className="col-md-12">
              <div className="about_right section_head">
                <h2 className="title">
                 <span>delivery</span> policy
                </h2>
                <p>
                For shipping of certificate option the company will be responsible for dealing with the third party to deliver the certificate for the student to the address the student assigned on the website with the phone number the student enter on the website with the full responsibility of the third party chosen by the company on the delivery of the certificate without any harm so refuse to receive the certificate if it contains any cut also the expenses for delivery of certificate will depend on your address and will differ from address to another.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}
    </div>
  );
};
export default Description;
