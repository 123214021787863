import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const PaymentAlert = (props) => {
  return (
    <div className="container-fluid">
      <AlertDismissibleExample
        variant={props.variant}
        setShowAlert={props.setShowAlert}
        payment_msg={props.payment_msg}
      ></AlertDismissibleExample>
    </div>
  );
};

function AlertDismissibleExample(props) {
    
  const [show, setShow] = useState(true);
//   const payment_msg = JSON.parse(sessionStorage.getItem("payment_msg"));

  return (
    <Alert
   
      show={show}
      variant={props.variant}
      onClose={() => {
        setShow(false);
        sessionStorage.removeItem("payment_msg");
        props.setShowAlert(false)
      }}
      dismissible
      style={{
        position: "fixed",
        top: 200,
        right: 200,
        left: 200,
        zIndex: 9,
      }}
    >
      {props.payment_msg ? (
         props.payment_msg=='nodata'?
          <p>
         Sorry something wrong happened check your data again or check your card authentication , you can try again or call our support
        
        </p>
          :
        <p>
          Thanks for pay ,Your amount paid
          up is {props.payment_msg.amount}, We hope enjoy your {props.payment_msg.type} <span>{props.payment_msg.first_name}</span> 
        </p>
      ) : (
        ""
      )}
    </Alert>
  );
}
export default PaymentAlert;
