import React, { useState, useEffect } from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2 className="title">
                  business<span> description</span>
                </h2>
                <p>
                  <span>ICBS</span> company targets to establish special
                  community type which will be mix of schooling, social media
                  department, freelancing department and new technical service
                  provider that will be induced inside the worldwide market.
                  This community will start giving his students a predesigned
                  syllabus through 9 modules life trip and monitoring their
                  progress online then giving them 6 milestones project through
                  which they can choose any project type they want to establish
                  and work over it to extract suitable final product from it to
                  be introduced inside the market. We will provide a social
                  media department which will differs totally from any social
                  media application in addition to freelancing department and
                  new induced technical services into future market after we
                  completed the required steps inside our school community.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}
    </div>
  );
};
export default Description;
