

const envType = window.location.origin // Allowd is dev and prod


 var newDomain = "https://sub.icbschools.com/api"
const apiEndPointsConfig = {
  newApiDomain: `${newDomain}/gateway/`,
  systemDomin:`${newDomain}/system/`,
};



export default apiEndPointsConfig