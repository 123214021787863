import React, { useState, useEffect } from "react";
import axios from "axios";
import apiEndPointsConfig from "../Api/config";
import ClipLoader from "react-spinners/ClipLoader";
import Toast from "../Components/Toast";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import PaymentAlert from "../Components/PaymentAlert";
const Home = () => {
  let location = useLocation();

  const { token } = useSelector((state) => ({
    token: state.Auth.token,
  }));
  const [email, setEmail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [message, setmessage] = useState("");
  const [additional_details, setadditional_details] = useState("");
  const [isLoading, setisLoading] = useState("");
  const [messageSent, setmessageSent] = useState(null);
  const [ShowAlert, setShowAlert] = useState(false);
  const [payment_msg, setPayment_msg] = useState("");

  let parms = useParams();
  let payment_status = parms.payment_status;
  // payment_success_info
  const SendEmail = (e) => {
    setisLoading(true);
    e.preventDefault();
    const data = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      message: message,
      additional_details: additional_details,
    };
    axios
      .post(apiEndPointsConfig.newApiDomain + "contact_us", data)
      .then((response) => {
        setisLoading(false);
        setEmail("");
        setfirst_name("");
        setlast_name("");
        setmessage("");
        setadditional_details("");
        setmessageSent(true);
      })
      .catch((err) => {});
  };
  const paymentSuccessInfo = () => {
    setisLoading(true);

    const data = {
      transaction_id: location.search.replace("?trx=", ""),
    };
    axios
      .post(apiEndPointsConfig.newApiDomain + "payment_success_info", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
       
       if (response.data.message == "success") {
          sessionStorage.setItem("payment_msg", JSON.stringify(response.data));
          window.location.href = "/";
        }
         else {
          sessionStorage.setItem("payment_msg", "nodata");
          window.location.href = "/";
        }
      })
      .catch((err) => {
        if(err.response.data.message=='NotHavePayment'){
          window.location.href = "/";
        }
        else{
           sessionStorage.setItem("payment_msg", "nodata");
        window.location.href = "/";
        }
       
      });
  };
  useEffect(() => {
    if (token) {
      if (payment_status && location.search) {
        paymentSuccessInfo();
      } 
      // else if (payment_status && !location.search) {
      //   sessionStorage.setItem("payment_msg", "nodata");
      //   window.location.href = "/";
      // }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (sessionStorage.getItem("payment_msg") == "nodata") {
        setPayment_msg("nodata");
      } else if (sessionStorage.getItem("payment_msg") != "nodata") {
        setPayment_msg(JSON.parse(sessionStorage.getItem("payment_msg")));
      }
    }
  }, [token]);

  //
  useEffect(() => {
    if (sessionStorage.getItem("payment_msg")) {
      setShowAlert(true);
    }
  }, [token]);
  //
  return (
    <div>
      {ShowAlert && (
        <PaymentAlert
          payment_msg={payment_msg}
          variant={`${payment_msg == "nodata" ? "danger" : "success"}`}
          setShowAlert={setShowAlert}
        />
      )}
      {/*  <PaymentAlert var="danger"/> */}
      {messageSent == true ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="Your message sent successfully"
          type="success"
        />
      ) : messageSent == false ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="Something went wrong, please try again"
          type="danger"
        />
      ) : (
        ""
      )}
      <section id="banner_part">
        <div class="slide_active">
          <div class="banner_item" data-bg-image="assets/images/banner_1.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  <h4>
                    Welcome to <span> ICBS </span>world. here, you will find
                    your Bright future.
                  </h4>
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_2.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  <h4>
                    <span>Believe</span> that you can be yourself change and
                    also the world change.
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_3.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    You will not ever find an easy way to <span>INVADE </span>
                    the world <br /> you must pass through world of swords and
                    snakes.
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_4.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    Always believe that it is not over until you reach your{" "}
                    <span>GOAL</span>,<br /> do not surrender it won’t be easy.
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/work-4.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    People grow through <span>LEARNING </span>and pain if you
                    still feel pain <br /> so you still need to learn more.{" "}
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_5.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    Always believe in two things, you are not suitable but you{" "}
                    <span>CAN</span> be oneday.
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_6.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    After your graduation from <span>ICBS</span> program,
                    <br /> you will find yourself one of the world masters{" "}
                    <br /> so behave as the good version of those masters
                    <br /> and leave your ego before registering the program.
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div class="banner_item" data-bg-image="assets/images/banner_8.jpg">
            <div class="container text-center">
              <div class="row">
                <div class="banner_text">
                  {/* <h3>WELCOME on <span>spirit</span></h3> */}
                  <h4>
                    The pain of <span>knowledge</span> is an hour but the pain
                    of ignorance is eternal.{" "}
                  </h4>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet porro eaque assumenda consequatur asperiores laboriosam debitis, explicabo.</p> */}
                  {/* <a href="#" class="multi_button">
                    Know More
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="banner_arrows">
          <i class="fa fa-angle-left banner_arrow_left"></i>
          <i class="fa fa-angle-right banner_arrow_right"></i>
        </div>
      </section>
      {/*  Banner Part End  */}

      {/*  About Part Start  */}
      <section id="about_part">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2>
                  <span>Some words</span> about us
                </h2>
                <p>
                  ICBS is a new type of educational organization and unique
                  society in which you will learn all types of science fields
                  including engineering, medicine, economy, Etc. so that the
                  student learn to be professional master and can start his own
                  career concerning any type of science he concern or having his
                  own start-up after growing his own character through a trip of
                  awareness, pain and happiness to create the suitable character
                  for his dreams.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}

      {/*  Contact Part Start  */}
      <section id="contact_part">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="section_head contact_head text-center">
                <h2>
                  Contact <span>us</span>
                </h2>
              </div>
            </div>
            <div class="col-md-5 col-sm-12 order-md-3 mb-4 mb-md-0">
              <div class="contact_info">
                <ul>
                  <li class="c_call">
                    <i class="fa fa-phone"></i>
                    <aside>
                      <h3>Give us a Call</h3>
                      <a href="callto:+201023113710">+20 102 311 3710</a>
                    </aside>
                  </li>
                  <li class="c_mail">
                    <i class="fa fa-envelope-o"></i>
                    <aside>
                      <h3>Send a Mail</h3>
                      <a href="mailto:icbschools.contact@gmail.com">
                        icbschools.contact@gmail.com
                      </a>
                    </aside>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-7 col-sm-12">
              <div class="contact_form">
                <form class="form-group" onSubmit={SendEmail}>
                  <div class="row">
                    <div class="col-md-6">
                      <input
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                        type="text"
                        class="form-control c_name"
                        placeholder="First Name *"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                        type="text"
                        class="form-control c_email"
                        placeholder="Last Name *"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        class="form-control c_email"
                        placeholder="Email *"
                        required
                      />
                    </div>
                    <div class="col-md-6">
                      <input
                        value={additional_details}
                        onChange={(e) => setadditional_details(e.target.value)}
                        type="text"
                        class="form-control c_num"
                        placeholder="Message Title *"
                        required
                      />
                    </div>
                    <div class="col-sm-12">
                      <textarea
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                        class="form-control c_textarea"
                        placeholder="Your Message *"
                        required
                      ></textarea>
                    </div>
                    <div class="col-sm-12 text-center">
                      <button type="submit" class="btn btn-primary submit_btn">
                        {isLoading ? (
                          <ClipLoader size={24} color={"#fff"} loading={true} />
                        ) : (
                          "Submit Now"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        {/* <div id="map_part">
            <div id="map" class="map_part">
            </div>
            <div class="clearfix"></div>
        </div> */}
      </section>
      {/*  Contact Part End  */}
    </div>
  );
};
export default Home;
