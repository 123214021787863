import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../Api/config";
import SimpleImageSlider from "react-simple-image-slider";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import ReactPaginate from "react-paginate";

const FutureTargets = () => {
  const [FutureTargetsList, setFutureTargets] = useState([]);
  const [page, setPage] = useState(1);

  const Getfuture_target = () => {
    axios
      .get(apiEndPointsConfig.newApiDomain + "future_target", {})
      .then((response) => {
        setFutureTargets(response.data.data);
      })
      .catch((err) => {});
  };
  const SlideChange = (event) => {
    setPage(event+1)
  };
  useEffect(() => {
    Getfuture_target();
  }, []);
  return (
    <div>
      <section className="page">
        <div class="target_banner">
          <section id="target_part">
            <Slider
            infinite={true}
            autoplay={7000}

              onSlideChange={(event) => SlideChange(event.slideIndex)}
            >
              {FutureTargetsList.map((slide, index) => (
                <div key={index}>
                  <div
                    className="slide_cover"
                    style={{
                      backgroundImage: `url(${slide.image})`,
                    }}
                  >
                    <h4
                      data-text={`${slide.text + " ..."}`}
                      className="slide_text"
                    >
                      {slide.text + " ..."}
                    </h4>
                    <h6>{slide.text}</h6> 
                
                  </div>
                  <div className="custom-pagination col-12">
                    <h6>{page}</h6>
              </div>
                </div>
              ))}
             
            </Slider>
          </section>
        </div>
      </section>

      {/*  About Part End  */}
    </div>
  );
};

export default FutureTargets;
