import axios from "axios";
import cookie from "js-cookie";

import {
  REGISTER,
  AUTHENTICATE,
  DEAUTHENTICATE,
  ERRORAUTHENTICATE,
  RESETAUTHENTICATE,
  USERISLOGOUT,
} from "../actionTypes";
////API///
import apiEndPointsConfig from "../../Api/config";

export const authenticate = (data) => (dispatch) =>
  setCookie("iCPS_token", data.token);

export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
      path: "/",
    });
  }
};

export const removeCookie = (key) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = (key) => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(";")
    .find((c) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split("=")[1];
};

// gets the token from the cookie and saves it in the store
export const reauthenticate = () => async (dispatch) => {
  let token = cookie.get("iCPS_token");
  if (token) {
    await axios
      .get(apiEndPointsConfig.newApiDomain + "user_profile", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.data)
        if (res.data.code == 401) {
          removeCookie("iCPS_token");
          dispatch({ type: DEAUTHENTICATE });
          window.location.href = "/";
        } else {
          dispatch({
            type: AUTHENTICATE,
            payload: token,
            id: res.data.data.id,
            first_name: res.data.data.first_name,
            gender: res.data.data.gender,
            last_name: res.data.data.last_name,
            mobile: res.data.data.mobile,
            email: res.data.data.email,
            address: res.data.data.address,
            age: res.data.data.age,
            category: res.data.data.category,
            country: res.data.data.country,
            dream: res.data.data.dream,
            educational_grade_year: res.data.data.educational_grade_year,
            educational_grade_type:res.data.data.educational_grade_type,
            module: res.data.data.module,
            referral_code: res.data.data.referral_code,
            avatar:res.data.data.avatar,
            referrer_by: res.data.data.referrer_by,
            subscription: res.data.data.subscription,
            user_name: res.data.data.user_name,
          });
          
          localStorage.setItem('iCPS_token',token)
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  } else {
    dispatch({ type: USERISLOGOUT });
  }
};

// removing the token
export const deauthenticate = () => {
  return (dispatch) => {
    removeCookie("iCPS_token");
    dispatch({ type: DEAUTHENTICATE });
    localStorage.removeItem('iCPS_token')
    sessionStorage.removeItem("payment_msg")
    window.location.href = "/";
  };
};

export const checkServerSideCookie = (ctx) => {
  if (ctx.isServer) {
    //if (ctx.req.headers.cookie) {

    const token = getCookie("token", ctx.req);

    ctx.store.dispatch(reauthenticate(token));
    //getUserData(token,ctx)
    //}
  } else {
    const token = ctx.store.getState().Auth.token;

    if (
      token &&
      (ctx.pathname === "/login" ||
        ctx.pathname === "/register" ||
        ctx.pathname === "/register-corporate")
    ) {
      setTimeout(function () {
        window.location.href = "/";
      }, 0);
    }
  }
};
export const resetLogin = () => (dispatch) => {
  dispatch({ type: RESETAUTHENTICATE });
};

export const checkClienSideCookie = () => {
  let token = cookie.get("token");
};
