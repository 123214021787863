import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Payment = ({ authenticate }) => {
  const { UserInfo } = useSelector((state) => ({
    UserInfo: state.Auth,
  }));
  const CopyValue = UserInfo.referral_code;
  const [copied, setcopied] = useState(false);
  return (
    <div className="page">
      {copied ? (
        <Toast
          showItem={true}
          timeOut={3000}
          message="Copied to clipboard"
          type="success"
        />
      ) : (
        ""
      )}
      <div class="container">
        <div className="M_t_17">
          <div className="row">
            {UserInfo.category && UserInfo.category.is_late == 1 ? (
              ""
            ) : (
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <Link
                      onClick={() =>
                        (window.location.href = `/payment-certficate`)
                      }
                    >
                      {" "}
                      <div className="d-flex flex-column align-items-center text-center">
                        <img
                          src="assets/images/medal.svg"
                          alt=""
                          className=""
                          width="200"
                        />

                        <div className="mt-3">
                          <h4>Certificate Payment</h4>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {UserInfo.category?

              (UserInfo.category.id != 1 ||
              (UserInfo.category.id == 1 && UserInfo.category.is_late == 1)) &&
              UserInfo.module && (
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <Link
                        onClick={() =>
                          (window.location.href = `/payment-subscription`)
                        }
                      >
                        <div className="d-flex flex-column align-items-center text-center">
                          <img
                            src="assets/images/salary.svg"
                            alt=""
                            className=""
                            width="200"
                          />

                          <div className="mt-3">
                            <h4>Monthly Subscription</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )
              :''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
