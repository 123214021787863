import React, {useState, useEffect} from 'react'

const Toast = (props) => {
    const [show, setShow] = useState(false)
    
    const {showItem} = props

    let timeOut = props.timeOut? props.timeOut: 10000
    useEffect(() => {
        if(showItem){
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, timeOut);
        } else {
            setShow(false)
        }
    }, [showItem])
    if(show){
        return (
            <div className={`toast-custom ${props.type? props.type : ''}`}>
                <div className="toast-message">
                    {props.message? props.message : ''}
                </div>
                <div className="toast-action">
                    <a className={`btn ${props.type? 'btn-light' : 'btn-dark'}`} onClick={() => setShow(false)}>Close</a>
                </div>
            </div>
        )
    } else {
        return ''
    }
}

export default Toast