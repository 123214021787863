import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
import { Modal } from "react-bootstrap";
import Loader from "../../Components/Loader"
const Payment = ({ authenticate }) => {
  const { token, UserInfo } = useSelector((state) => ({
    UserInfo: state.Auth,
    token: state.Auth.token,
  }));
  let location = useLocation();
  const [certifcate_fees, setcertifcate_fees] = useState(0);
  const [ShowFees, setShowFees] = useState(false);
  const [FileIsUplode, setFileIsUplode] = useState(false);
  const [type_uplode, setType] = useState("");
  const [fileUploded, setfileUploded] = useState(null);
  const [monthly_count, setmonthly_count] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [ShowFrame, setShowFrame] = useState(false);
  const [FrameLink, setFrameLink] = useState(null);
  
  
  const Getpayment_certficate_result = () => {
    axios
      .get(apiEndPointsConfig.newApiDomain + "payment_certficate_result", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setcertifcate_fees(response.data.data.certifcate_fees);
      })
      .catch((err) => {});
  };
  const Getpayment_subscription_result = (monthly_count) => {
    setmonthly_count(monthly_count);
    axios
      .post(
        apiEndPointsConfig.newApiDomain + "monthly_subscription_result",
        monthly_count,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setcertifcate_fees(response.data.data.monthly_count_fees);
        setShowFees(true);
      })
      .catch((err) => {});
  };
  const payWithVisa = () => {
    setIsloading(true)
    const paymentRequestcertifcate = {
      amount: certifcate_fees,
      type: "certifcate",
    };

    const paymentRequestMonthely = {
      amount: certifcate_fees,
      type: "monthly_fees",
      monthly_count: location.search.replace("?months=", ""),
    };
    axios
      .post(
        apiEndPointsConfig.newApiDomain + "pyment_service",

        window.location.pathname == "/payment-certficate-method"
          ? paymentRequestcertifcate
          : paymentRequestMonthely,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
      setIsloading(false)
      setShowFrame(true)
      setFrameLink(response.data.data)
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (token) {
      if (window.location.pathname == "/payment-certficate-method") {
        Getpayment_certficate_result();
      }
    }
  }, [token]);
  useEffect(() => {
    if (token) {
      if (window.location.pathname != "/payment-certficate-method") {
        Getpayment_subscription_result();
      }
    }
  }, [token]);
  return (
    <div className="page">
      <UploadFile
        setfileUploded={setfileUploded}
        show={FileIsUplode}
        token={token}
        type_uplode={type_uplode}
        monthly_count={monthly_count}
        onHide={() => setFileIsUplode(false)}
      />
      {fileUploded == true ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message={"Payment details are being reviewed by admins now"}
          type="success"
        />
      ) : fileUploded == false ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="Something went wrong, please try again"
          type="danger"
        />
      ) : fileUploded == "wait" ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="You still have a pending request, wait for it to be reviewed by the admin"
          type="danger"
        />
      ) : (
        ""
      )}
      <div class="container">

        <div className="M_t_17">
          {isLoading?
          <Loader/>:
          <div className="row">
            {ShowFrame &&
            <VisaFrame FrameLink={FrameLink} show={ShowFrame} onHide={() => setShowFrame(false)} ></VisaFrame>
           }
            <div className="col-md-12">
              <h3>Select payment method</h3>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <a onClick={() => payWithVisa()}>
                    <div className="d-flex flex-column align-items-center text-center">
                      <img class="Payment_img" src="assets/images/Visa.png" />
                      <div className="mt-3">
                        <h4>Online payment</h4>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  
                  <a
                    onClick={() => {
                      setFileIsUplode(true);
                      setType(
                        window.location.href.includes("payment-certficate-method")
                          ? "payment_certficate"
                          : "monthly_subscription_pay"
                      );
                    }}
                  >
                    <div className="d-flex flex-column align-items-center text-center">
                      <img class="Payment_img" src="assets/images/folder.png" />
                      <div className="mt-3">
                        <h4>Upload payment document</h4>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
 } </div>
      </div>
    </div>
  );
};
function UploadFile(props) {
  let formData = new FormData();
  const [fileSrc, setFileSrc] = useState("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const uploadFiles = () => {
    inputUploadFile.current.click();
  };
  const onFileChange = (e) => {
    setFileName(e.target.files[0].name);
    setFileSrc(e.target.files[0]);
  };

  const inputUploadFile = useRef(null);
  const submitUplode = () => {
    props.setfileUploded(null);
    if (props.type_uplode == "monthly_subscription_pay") {
      formData.append("monthly_count", props.monthly_count.monthly_count);
    }
    formData.append("file", fileSrc);
    setIsLoading(true);
    axios
      .post(apiEndPointsConfig.newApiDomain + props.type_uplode, formData, {
        headers: {
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        props.onHide();
        setIsLoading(false);
        props.setfileUploded(true);
        resetFileData();
      })
      .catch((err) => {
        if (err.response.data.code == 422) {
          props.setfileUploded("wait");
        } else {
          props.setfileUploded(false);
        }
        setIsLoading(false);
        props.onHide();

        resetFileData();
      });
  };
  const resetFileData = () => {
    setFileSrc("");
    setFileName("");
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dark-modal custom-modal modal-custom-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title> Upload your payment document (Images only) </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <a onClick={props.onHide} className="close-modal"><i className="fas fa-times"></i></a> */}

        <form>
          <div className="form-group my-4">
            <div className="input-group">
              <div className="input-group-prepend" onClick={uploadFiles}>
                <button className="btn btn-dark" type="button">
                  Select File
                </button>
              </div>
              <input
                type="text"
                disabled="disapled"
                defaultValue={fileName}
                className="form-control"
                placeholder="File name"
              />
              <input
                type="file"
                accept="image/*"
                onChange={onFileChange}
                hidden
                ref={inputUploadFile}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {fileSrc ? (
          <button
            type="button"
            onClick={submitUplode}
            disabled={isLoading}
            className={`btn btn-primary btn-loading ${
              isLoading ? "is-loding" : ""
            }`}
          >
            {isLoading ? (
              <ClipLoader size={24} color={"#fff"} loading={true} />
            ) : (
              "Upload"
            )}
          </button>
        ) : (
          <button
            type="button"
            disabled={true}
            className={`btn btn-dark btn-loading ${
              isLoading ? "is-loding" : ""
            }`}
          >
            Upload
          </button>
        )}
        <button type="button" className="btn btn-dark" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
function VisaFrame(props) {


  return (
    <Modal
      {...props}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="dark-modal custom-modal modal-custom-sm"
    >
      <Modal.Header closeButton>
        <Modal.Title>Online Payment </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <iframe src={props.FrameLink} title="Visa Payment"></iframe>
    </Modal.Body>
    </Modal>
  );
}
export default Payment;
