import React, { useState, useCallback, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import Cropper from "react-easy-crop";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getOrientation } from "get-orientation";
import getCroppedImg from "../../Components/userCrop/cropImage";
import { Modal } from "react-bootstrap";
const Profile = ({ authenticate }) => {
  const [fileSrc, setFileSrc] = useState("");
  const { UserInfo } = useSelector((state) => ({
    UserInfo: state.Auth,
  }));
  const CopyValue = UserInfo.referral_code;
  const [copied, setcopied] = useState(false);
  const [selectImage, setSelectImage] = useState({});
  const [modalCrop, setModalCrop] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const [isImageUpdated, setisImageUpdated] = useState();
  const [EditData, SetEditData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const inputUploadFile = useRef(null);

  const changeFile = () => {
    inputUploadFile.current.click();
  };

  const ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90,
  };

  const [email, setEmail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [dream, setDream] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");
 
  const [Address, setAddress] = useState("");
 const [DataUpdated, setDataUpdated] = useState(null);
  const EditUserProfile = () => {
    setIsLoading(true)
   
    axios
      .put(
        apiEndPointsConfig.newApiDomain + "user_profile",
        {
          first_name: first_name,
          last_name: last_name,
          email: email,
          mobile: mobile,
          age: age,
          address: Address,
          dream: dream,
          gender: gender,
        },
        {
          headers: {
            Authorization: `Bearer ${UserInfo.token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false)
        SetEditData(true)
        setDataUpdated(true)

      })
      .catch((err) => {
        setDataUpdated(false)
      });
  };

  useEffect(() => {
    if (UserInfo) {
      setfirst_name(UserInfo.first_name);
      setlast_name(UserInfo.last_name);
      setEmail(UserInfo.email);
      setmobile(UserInfo.mobile);
      setage(UserInfo.age);
      setAddress(UserInfo.address);
      setDream(UserInfo.dream);
      setgender(UserInfo.gender);
    }
  }, [UserInfo]);
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        //imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setSelectImage(imageDataUrl);
      setModalCrop(true);
    }
  };
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const changeUserPhoto = (data) => {
    setUserPhoto(data);
    setModalCrop(false);
  };
  useEffect(() => {
    if (UserInfo) {
      setUserPhoto(UserInfo.avatar);
    }
  }, [UserInfo]);
  return (
    <div className="page">
      {copied ? (
        <Toast
          showItem={true}
          timeOut={3000}
          message="Copied to clipboard"
          type="success"
        />
      ) : (
        ""
      )}
      {isImageUpdated == true ? (
        <Toast
          showItem={true}
          timeOut={3000}
          message="profile photo updated successfully"
          type="success"
        />
      ) : isImageUpdated == false ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="Something went wrong, please try again"
          type="danger"
        />
      ) : (
        ""
      )}
       {DataUpdated == true ? (
        <Toast
          showItem={true}
          timeOut={3000}
          message="profile Data updated successfully"
          type="success"
        />
      ) : DataUpdated == false ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message="Something went wrong, please try again"
          type="danger"
        />
      ) : (
        ""
      )}
      {/*  */}
      <UserCrop
        show={modalCrop}
        setisImageUpdated={setisImageUpdated}
        token={UserInfo.token}
        selectimage={selectImage}
        changeuserphoto={changeUserPhoto}
        onHide={() => setModalCrop(false)}
      />
      <div class="container-fluid px_fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  <div className="uplode_img">
                    {userPhoto ? (
                      <img
                        src={userPhoto}
                        alt=""
                        className="rounded-circle p-1 bg-primary"
                        width="200"
                      />
                    ) : UserInfo.gender == "male" ? (
                      <img
                        src="assets/images/profile.svg"
                        alt=""
                        className="rounded-circle p-1 bg-primary"
                        width="200"
                      />
                    ) : (
                      <img
                        src="assets/images/profileF.svg"
                        alt=""
                        className="rounded-circle p-1 bg-primary"
                        width="200"
                      />
                    )}
                    <a
                      className="link upload-photo mt-5 ml-4"
                      onClick={changeFile}
                    >
                      <img src="assets/images/upload-file.svg" />
                    </a>
                    <input
                      type="file"
                      onChange={onFileChange}
                      hidden
                      ref={inputUploadFile}
                    />
                  </div>

                  <div className="mt-3">
                    <h4>
                      {UserInfo.first_name} {UserInfo.last_name}
                    </h4>

                    <p className="text-secondary mb-1">{UserInfo.user_name}</p>
                    {/* <p className="text-muted font-size-sm">{UserInfo.address}</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                  {UserInfo.category && (
                    <div className="row mb-3 w-100 justify-content-between">
                      <div className="text-main">
                        <h6 className="mb-0">Category</h6>
                      </div>
                      <div className="text-secondary">
                        <p>{UserInfo.category.name}
                        {UserInfo.category && UserInfo.category.is_late == 1 && 
                        <span className="late_cat">Late</span> }</p>
                      </div>
                      {/* <p className="late_cat_text">* </p> */}
                    </div>
                   
                  )}

                  <div className="row mb-3 w-100 justify-content-between">
                    <div className="text-main">
                      <h6 className="mb-0">Module</h6>
                    </div>
                    <div className="text-secondary">
                      <p>{UserInfo.module ? UserInfo.module.name : "N/A"}</p>
                    </div>
                  </div>

                  {UserInfo.module && (
                    <div className="row mb-3 w-100 justify-content-between">
                      <div className="text-main">
                        <h6 className="mb-0"> Referral Code</h6>
                      </div>
                      <div className="text-secondary">
                        <CopyToClipboard
                          text={CopyValue}
                          onCopy={() => setcopied(true)}
                        >
                          <button className="referal">
                            {UserInfo.referral_code}
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}
                  <div className="row mb-3 w-100 justify-content-between">
                    <div className="text-main">
                      <h6 className="mb-0">Your Balance</h6>
                    </div>
                    <div className="text-secondary">
                      <p>
                        {UserInfo.balance_received
                          ? UserInfo.balance_received
                          : "0"}
                      </p>
                    </div>
                  </div>
                  
                
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-9">
            <div className="card px-3">
              <div className="card-body">
                <div className="row mb-3 justify-content-end">
                  {EditData && (
                    <button
                      type="submit"
                      onClick={() => SetEditData(false)}
                      className={`btn btn-primary btn-login login_button btn-md  btn-loading`}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">First Name</h6>
                  </div>

                  <div className="col-sm-4 text-secondary col-12">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={first_name}
                      onChange={(e) => setfirst_name(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-2 Center  col-12">
                    <h6 className="mb-0">Last Name</h6>
                  </div>
                  <div className="col-sm-4 text-secondary">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={last_name}
                      onChange={(e) => setlast_name(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">E-mail</h6>
                  </div>
                  <div className="col-sm-10 text-secondary">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Mobile</h6>
                  </div>
                  <div className="col-sm-10 text-secondary">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Age</h6>
                  </div>

                  <div className="col-sm-4 text-secondary col-12">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={age}
                      onChange={(e) => setage(e.target.value)}
                    />
                  </div>

                  <div className="col-sm-2 Center  col-12">
                    <h6 className="mb-0">Gender</h6>
                  </div>
                  <div className="col-sm-4 text-secondary">
                    <select
                      disabled={EditData}
                      className="form-control"
                      id="formGender"
                      onChange={(e) => setgender(e.target.value)}
                      required
                      value={gender}
                    >
                      <option value="male" selected>
                        Male
                      </option>
                      <option value="female">Female</option>
                    </select>

                    {/* <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={UserInfo.gender}
                    />*/}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Address</h6>
                  </div>
                  <div className="col-sm-10 text-secondary">
                    <input
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={Address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Your Dream</h6>
                  </div>
                  <div className="col-sm-10 text-secondary">
                    <textarea
                      disabled={EditData}
                      type="text"
                      className="form-control"
                      value={dream}
                      onChange={(e) => setDream(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3 justify-content-end">
                  {!EditData && (
                        <button
                        type="submit"
                        onClick={() => EditUserProfile()}
                        disabled={isLoading}
                        className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                          isLoading ? "is-loding" : ""
                        }`}
                      >
                        {isLoading ? (
                          <ClipLoader size={24} color={"#fff"} loading={true} />
                        ) : (
                          "Save"
                        )}
                      </button>
                   
                  )}
                </div>
              </div>
            </div>
            <div className="card px-3">
              <div className="card-body">
                {UserInfo.country && (
                  <div className="row mb-3">
                    <div className="col-sm-2 Center">
                      <h6 className="mb-0">Country</h6>
                    </div>
                    <div className="col-sm-10 text-secondary">
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={UserInfo.country.name}
                      />
                    </div>
                  </div>
                )}
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Educational Organization</h6>
                  </div>
                  <div className="col-sm-4 text-secondary">
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={UserInfo.educational_grade_type}
                    />
                  </div>
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Educational Grade Year</h6>
                  </div>
                  <div className="col-sm-4 text-secondary">
                    <input
                      readOnly
                      type="text"
                      className="form-control"
                      value={UserInfo.educational_grade_year}
                    />
                  </div>
                </div>

                {UserInfo.referrer_by && (
                  <div className="row mb-3">
                    <div className="col-sm-2 Center">
                      <h6 className="mb-0">Referrer By</h6>
                    </div>
                    <div className="col-sm-10 text-secondary">
                      <input
                        readOnly
                        type="text"
                        className="form-control"
                        value={
                          UserInfo.referrer_by.full_name +
                          " " +
                          " (" +
                          UserInfo.referrer_by.email +
                          " )"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="card px-3">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-2 Center">
                    <h6 className="mb-0">Your Subscription</h6>
                  </div>
                  <div className="col-sm-10 text-secondary">
                    {UserInfo.subscription ? (
                      <ul className="sub_list">
                        <li className="d-flex mb-3 w-100 justify-content-between">
                          <div className="text-main">
                            <h6 className="mb-0">Status</h6>
                          </div>
                          <div className="text-secondary">
                            <p
                              className={
                                UserInfo.subscription.status == "active"
                                  ? "active"
                                  : ""
                              }
                            >
                              {UserInfo.subscription.status}
                            </p>
                          </div>
                        </li>
                        <li className="d-flex mb-3 w-100 justify-content-between">
                          <div className="text-main">
                            <h6 className="mb-0">Start Date</h6>
                          </div>
                          <div className="text-secondary">
                            <p>{UserInfo.subscription.start_date}</p>
                          </div>
                        </li>
                        <li className="d-flex mb-3 w-100 justify-content-between">
                          <div className="text-main">
                            <h6 className="mb-0">End Date</h6>
                          </div>
                          <div className="text-secondary">
                            <p>{UserInfo.subscription.end_date}</p>
                          </div>
                        </li>
                        <li className="d-flex mb-3 w-100 justify-content-between">
                          <div className="text-main">
                            <h6 className="mb-0">Months Count</h6>
                          </div>
                          <div className="text-secondary">
                            <p>{UserInfo.subscription.months_count}</p>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <div>No subscription yet</div>
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const UserCrop = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [Image, setImage] = useState("");

  const { selectimage, changeuserphoto } = props;
  const { token } = useSelector((state) => ({
    token: state.Auth.token,
  }));

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const UpdateuserPhoto = (file) => {
    let formData = new FormData();

    formData.append("avatar", file);
    axios
      .post(apiEndPointsConfig.newApiDomain + "user_avatar", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.message == "success") {
          props.setisImageUpdated(true);
          window.location.href = "/profile";
        }
      })
      .catch((err) => {
        props.setisImageUpdated(false);
      });
  };
  // const base64ToformData=(base64)=>{
  //   fetch(base64).then(res => res.blob()).then(blob => {
  //     const formData = new FormData();
  //     const file = new File([blob], "filename.jpeg");
  //     formData.append('image', file)
  //   })
  // };
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        selectimage,
        croppedAreaPixels,
        rotation
      );
      fetch(croppedImage)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "filename.jpeg");

          setImage(file);
          UpdateuserPhoto(file);
        });

      setCroppedImage(croppedImage);
      changeuserphoto(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="dark-modal custom-modal crop-modal"
      >
        <Modal.Body>
          <div className="crop-container">
            <Cropper
              image={selectimage}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              showGrid={false}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="crop-action">
            <button onClick={showCroppedImage} className="btn button_payment">
              Save
            </button>
            <button onClick={props.onHide} className="btn btn-dark ml-2">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Profile;
