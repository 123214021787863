import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Main from "../layout/main";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
// ;
// Home Page
import Home from "../Pages/Home";
import Description from "../Pages/Description";
import ServicePricing from "../Pages/servicePricing";
import RefundCancellation from "../Pages/RefundCancellation";
import DeliveryPolicy from "../Pages/DeliveryPolicy";
import TermsandConditions from "../Pages/TermsConditions";
import FutureTargets from "../Pages/FutureTargets";
import Privacypolicy from "../Pages/PrivaciyPolice";
import Profile from "../Pages/User/Profile";
import Payment from "../Pages/User/Payment";
import PaymentHistory from "../Pages/User/PaymentHistory";
import PaymentMethod from "../Pages/User/Payment_method";
import ReferralCode from "../Pages/User/ReferralsList";

import PaymentResult from "../Pages/User/Payment_result";
import Login from "../Pages/auth/login";
import SignUP from "../Pages/auth/SignUP";
import Resetpassword from "../Pages/auth/ResetPasword";
export default () => {
  return (
    <Router>
      <ScrollRestoration />
      <Switch>
        <Route path="*">
          <GlobalLayout />
        </Route>
      </Switch>
    </Router>
  );
};

const GlobalLayout = () => {
  const isLogin = localStorage.getItem("iCPS_token") ? true : false;
  let location = useLocation();
  function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          authed === true ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
  return (
    <div className="wrap-pages">
      <Switch location={location}>
      <Route path="/login" component={Login} />
        <Route path="/registration" component={SignUP} />
        <Route path="/reset-password" component={Resetpassword} />
      <Route>
          <Main>
            <Switch>
              <Route
                path="/terms-and-conditions"
                component={TermsandConditions}
              />
              <Route path="/future-targets" component={FutureTargets} />

              <Route path="/business-description" component={Description} />
              <Route path="/service-pricing" component={ServicePricing} />
              <Route
                path="/refund-and-cancellation"
                component={RefundCancellation}
              />
              <Route path="/privacy-policy" component={Privacypolicy} />

              <Route path="/delivery-policy" exact component={DeliveryPolicy} />
              <PrivateRoute
                authed={isLogin}
                path="/profile"
                exact
                component={Profile}
              />
              <PrivateRoute
                authed={isLogin}
                path="/payment-certficate"
                exact
                component={PaymentResult}
              />
              <PrivateRoute
                authed={isLogin}
                path="/payment-subscription"
                exact
                component={PaymentResult}
              />
              <PrivateRoute
                authed={isLogin}
                path="/payment-history"
                exact
                component={PaymentHistory}
              />
              <PrivateRoute
                authed={isLogin}
                path="/referral-code-usage"
                exact
                component={ReferralCode}
              />

              <PrivateRoute
                authed={isLogin}
                path="/payment"
                exact
                component={Payment}
              />
              <PrivateRoute
                authed={isLogin}
                path="/payment-certficate-method"
                exact
                component={PaymentMethod}
              />
              <PrivateRoute
              name="Home"
                authed={isLogin}
                path="/:payment_status"
                exact
                component={Home}
              />
            {/* 374552759 */}

              <Route path="/" component={Home} />
            </Switch>
          </Main>
        </Route>
    
    
  </Switch>
    </div>
  );
};

// Scroll Restoration
const ScrollRestoration = () => {
  // Scroll Restoration
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
