import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
const Profile = ({ authenticate }) => {
  const { UserInfo } = useSelector((state) => ({
    UserInfo: state.Auth,
  }));
  const CopyValue = UserInfo.referral_code;
  const [copied, setcopied] = useState(false);
  const [payments, setpayments] = useState([]);
  const [totalCount, setTotaCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(false);
  const handlePageClick = async (data) => {
    setCurrentPage(data.selected + 1);
  };
  // const handleGoTopage = (value) => {
  //   setGoToPageValue(value)

  // }
  // const submitChangePage = (e) => {
  //   e.preventDefault();
  //   setCurrentPage(goToPageValue)

  // }
  // /user_referral?page=1
  const Getuser_payment = () => {
    setloading(true);
    axios
      .get(
        apiEndPointsConfig.newApiDomain + "user_payment?page=" + currentPage,
        {
          headers: { Authorization: `Bearer ${UserInfo.token}` },
        }
      )
      .then((response) => {
        setpayments(response.data.data);
        console.log(response.data.data);
        setloading(false);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if (UserInfo.token) {
      Getuser_payment();
    }
  }, [UserInfo]);
  return (
    <div className="page">
      {copied ? (
        <Toast
          showItem={true}
          timeOut={3000}
          message="Copied to clipboard"
          type="success"
        />
      ) : (
        ""
      )}
      <div class="container-fluid px_fluid">
        <div className="row">
          <h2 className="page_title">Payment History</h2>
          <div className="col-md-12">
            <div className="card px-3">
              <div className="card-body">
                <div >
                  <LoadingMask
                    loading={loading ? loading : false}
                    text={"loading..."}
                  >
                    {payments.length != 0 ? (
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th>Type</th>
                            <th>status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payments.map((pay) => {
                            return (
                              <tr>
                                <td >{pay.created_at}</td>
                                <td >{pay.amount}</td>
                                <td >{pay.payment_method}</td>
                                <td >{pay.type}</td>
                                <td >{pay.status}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <div>No Payments yet</div>
                    )}
                  </LoadingMask>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
