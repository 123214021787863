import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import Toast from "../../Components/Toast";
const Login = ({ authenticate }) => {
  const { token } = useSelector((state) => ({
    token: state.Auth.token,
  }));
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmationPassword, setconfirmationPassword] = useState();
  
  const [isLoading, setIsLoading] = useState(false);
  const [userLoged, setuserLoged] = useState(false);
  const [userName, setuserName] = useState("");

  const [message, setErroeMesage] = useState(false);

  const [ForgetPass, setForgetPass] = useState(false);

  const [Emailmesage, setEmailmesage] = useState(null);
  const [done, setdone] = useState(false);
  let location = useLocation();
  
  const [done_reset, setdone_reset] = useState(null);
 const handleSubmitEmail=(e)=>{
    setIsLoading(true)
    e.preventDefault();
    sendResetPassword()
    
};
const sendResetPassword = () => {
  const reset_token = location.search?location.search.replace('?', '') : ''
 
   const Data={
    "token":reset_token,
    "password":password,
    "password_confirmation":confirmationPassword
}
    axios.put(apiEndPointsConfig.newApiDomain + "reset_password", Data)
    .then(response => {
      setIsLoading(false)
        window.location.href='/login?password_reset'

    })
    .catch(err => {
        setEmailmesage(err.response.data.error)
        setIsLoading(false)
    });
}
const Reset_password_token = () => {
  const r_token = location.search?location.search.replace('?', '') : ''
 
    const reset_token={
         "token":r_token,
 }
     axios.post(apiEndPointsConfig.newApiDomain + "reset_password_token", reset_token)
     .then(response => {
    
         setdone_reset(true)
 
     })
     .catch(err => {
      setdone_reset(false)
     });
 }

  useEffect(() => {
    if (token) {
      window.location.href = "/";
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      Reset_password_token()
    }
  }, [token]);

  
  return (
    <div className="login-page row">
      {userLoged ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message={`Welcome ${userName}`}
          type="success"
        />
      ) : (
        ""
      )}
      <div className="login_container  col-md-7">
        <div className="login-form">  
        {done_reset==false?(
               <div className=" form-group col-md-12">
               <div className="error_mesage ">
                 <p>This link is no longer valid</p>
               </div>
               <div className="text-right form-group col-md-12 d-flex justify-content-between ">
              <Link to='/'>
                <span className="link forgot-password">Home</span>
              </Link>
            
            </div>
             </div>
           ) :
          <div >
               <div className="login-head">
            <h2 className="login_title">Reset your password</h2>
         
          </div>

          <form className="row" onSubmit={handleSubmitEmail}>
            {Emailmesage ? (
              <div className=" form-group col-md-12">
                <div className="error_mesage ">
                  <p>{Emailmesage}</p>
                </div>
              </div>
            ) : (
              ""
            )}
          
            <div className="form-group col-md-12">
              <label htmlFor="formPassword">New Password </label>
              <input
                type="password"
                className="form-control"
                id="formPassword"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="formPassword">New Password confirmation </label>
              <input
                type="password"
                className="form-control"
                id="formPassword"
                onChange={(e) => setconfirmationPassword(e.target.value)}
                required
              />
            </div>
            <div className="text-right form-group col-md-12 d-flex justify-content-between ">
              <Link onClick={()=>setForgetPass(false)}>
                <span className="link forgot-password">Back to login</span>
              </Link>
              <button
                type="submit"
                disabled={isLoading}
                className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                  isLoading ? "is-loding" : ""
                }`}
              >
                {isLoading ? (
                  <ClipLoader size={24} color={"#fff"} loading={true} />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>

          </div>
           }   <div className="login-lower text-center">
            <div className="links">
              <Link className="link_footer"> Terms </Link>|
              <Link className="link_footer"> Privacy </Link>
            </div>
            <p> Copyright © 2021 | ICBS </p>
          </div>
        </div>
      </div>
      <div
        className="image_container col-md-5 col-12"
        style={{
          backgroundImage: `url(assets/images/pexels-fauxels-3184287.jpg)`,
        }}
      >
        <div className="overlay">
          <Link
            onClick={() => (window.location.href = "/")}
            className="btn-back"
          >
            <img src="assets/images/close.svg" alt="close" />
          </Link>
          <div className="logo_data">
            <a class="navbar-brand main_logo" >
              <img src="assets/images/logo.png" alt="LOGO" />
              <h3>ICBS</h3>
            </a>
            <p className="mb-2">Here, you will find your Bright future.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state, { authenticate })(Login);
