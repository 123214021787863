import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Stepper } from "react-form-stepper";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import apiEndPointsConfig from "../../Api/config";
import { authenticate } from "../../Store/actions/Auth";
import { connect } from "react-redux";
import Toast from "../../Components/Toast";
const Registration = ({ authenticate }) => {
  const { token } = useSelector((state) => ({
    token: state.Auth.token,
  }));

  const [errorList, serErrorList] = useState([]);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [user_name, setuser_name] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("Male");
  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");
  // step 2
  const [educational_grade_year, setEducational_grade_year] = useState("");
  const [educational_grade_type, setEducational_grade_type] = useState("");
  const [dream, setDream] = useState("");

  const [countries, setcountries] = useState([]);
  const [Country, setcountry] = useState(1);
  const [Address, setAddress] = useState("");
  const [code, setCodee] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [userLoged, setuserLoged] = useState(false);
  const [userName, setuserName] = useState("");
  const [message, setErroeMesage] = useState(false);
  const [message_c_p, setmessage_c_p] = useState(false);
  
  const NextStep = (event) => {

    event.preventDefault();
    if(step==0){
      if(password==confirm_password){
         setStep(step + 1);
      }
      else{
        setmessage_c_p(true)
      }
    }
    else{
      setStep(step + 1);
    }
   
  };
  const BackStep = () => {
    // event.preventDefault();
    setStep(step - 1);
  };
  const NextStepRegistrUser = (event) => {
    setErroeMesage(false);
    event.preventDefault();
    const RegistrUser = {
      email: email,
      last_name: last_name,
      first_name: first_name,
      user_name: user_name,
      age: age,
      gender: gender,
      mobile: mobile,

      educational_grade_year: educational_grade_year,
      educational_grade_type: educational_grade_type,
      dream: dream,

      password: password,
      confirm_password: confirm_password,

      address: Address,
      country_id: Country,
      referral_code: code,
    };
    axios
      .post(apiEndPointsConfig.newApiDomain + "register", RegistrUser)
      .then((response) => {
        authenticate(response.data);
        setIsLoading(false);
        setuserLoged(true);
        setuserName(
          response.data.data.first_name + " " + response.data.data.last_name
        );
        window.location.href = "/";
      })
      .catch((error) => {
        setErroeMesage(true);

        serErrorList(error.response.data.error);
        setIsLoading(false);
        setStep(0);
      });
    //
    setIsLoading(true);
  };

  const getcountries = () => {
    axios
      .get(apiEndPointsConfig.newApiDomain + "countries_list")
      .then((response) => {
        setcountries(response.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (token) {
      window.location.href = "/";
    }
    getcountries();
  }, [token]);
  return (
    <div className="login-page row">
      {userLoged ? (
        <Toast
          showItem={true}
          timeOut={10000}
          message={`Welcome ${userName}`}
          type="success"
        />
      ) : (
        ""
      )}
      <div className="login_container  col-md-7">
        <div className="login-form">
          <div className="login-head">
            <h2 className="login_title">Registration</h2>
          </div>
          <Stepper
            steps={[
              { label: "personal data" },
              { label: "Educational & Geographical data" },
              { label: "who referred you" },
            ]}
            activeStep={step}
          />

          {step == 0 ? (
            <form className="row" onSubmit={NextStep}>
              {message_c_p ? (
                <div className=" form-group col-md-12">
                  <div className="error_mesage ">
                    <p>
                    Password and Confirm password Should match
                    </p>
                   
                  </div>
                </div>
              ) : (
                ""
              )}
              {message ? (
                <div className=" form-group col-md-12">
                  <div className="error_mesage ">
                    <p>
                      We had some problems creating your account Please check
                      the following and try again
                    </p>
                    <ul>
                      {errorList.map((error) => {
                        return <li>{error}</li>;
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="form-group col-md-12">
                <label htmlFor="formfirst_name">First Name</label>
                <input
                  value={first_name}
                  type="text"
                  className="form-control"
                  id="formfirst_name"
                  onChange={(e) => setfirst_name(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formlast_name">Last Name</label>
                <input
                  value={last_name}
                  type="text"
                  className="form-control"
                  id="formlast_name"
                  onChange={(e) => setlast_name(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formUser_name">User Name</label>
                <input
                  value={user_name}
                  type="text"
                  className="form-control"
                  id="formUser_name"
                  onChange={(e) => setuser_name(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="formAge">Age</label>
                <input
                  value={age}
                  type="number"
                  className="form-control"
                  id="formAge"
                  onChange={(e) => setage(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="formGender">Gender</label>
                <select
                  className="form-control"
                  id="formGender"
                  onChange={(e) => setgender(e.target.value)}
                  required
                  value={gender}
                >
                  <option value="male" selected>
                    Male
                  </option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="formUser_name">Email Address</label>
                <input
                  value={email}
                  type="email"
                  className="form-control"
                  id="formUser_name"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="formEmail">Mobile Number</label>

                <PhoneInput
                  required
                  defaultCountry="EG"
                  value={mobile}
                  onChange={setmobile}
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="formPassword">Password</label>
                <input
                  value={password}
                  type="password"
                  className="form-control"
                  id="formPassword"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="formConfirmPassword">Confirm Password</label>
                <input
                  value={confirm_password}
                  type="password"
                  className="form-control"
                  id="formConfirmPassword"
                  onChange={(e) => setconfirm_password(e.target.value)}
                  required
                />
              </div>

              <div className="text-right form-group col-md-12 ">
                
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                    isLoading ? "is-loding" : ""
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader size={24} color={"#fff"} loading={true} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </form>
          ) : step == 1 ? (
            <form className="row" onSubmit={NextStep}>
              <div className="form-group col-md-12">
                <label htmlFor="formeducational_grade_type">
                  Educational Organization
                </label>
                <input
                  value={educational_grade_type}
                  type="text"
                  className="form-control"
                  id="formeducational_grade_type"
                  onChange={(e) => setEducational_grade_type(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formEducational_grade_year">
                  Educational grade Year
                </label>
                <input
                  value={educational_grade_year}
                  type="number"
                  className="form-control"
                  id="formEducational_grade_year"
                  onChange={(e) => setEducational_grade_year(e.target.value)}
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formdraem">Your draem</label>
                <textarea
                  value={dream}
                  type="text"
                  className="form-control"
                  id="formdraem"
                  onChange={(e) => setDream(e.target.value)}
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formGender">Country</label>
                <select
                  className="form-control"
                  id="formGender"
                  onChange={(e) => setcountry(e.target.value)}
                  value={Country}
                >
                  {countries.map((country) => {
                    return <option value={country.id}>{country.name}</option>;
                  })}
                </select>
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="formaddress">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="formaddress"
                  onChange={(e) => setAddress(e.target.value)}
                  required
                  value={Address}
                />
              </div>
              <div className="justify-content-between d-flex form-group col-md-12">
                <button
                onClick={()=>BackStep()}
                  type="button"
                  disabled={isLoading}
                  className={`btn btn-primary btn-login back_btn btn-md  btn-loading ${
                    isLoading ? "is-loding" : ""
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader size={24} color={"#fff"} loading={true} />
                  ) : (
                    "Back"
                  )}
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-primary btn-login login_button btn-md btn-loading ${
                    isLoading ? "is-loding" : ""
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader size={24} color={"#fff"} loading={true} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </form>
          ) : (
            <form className="row" onSubmit={NextStepRegistrUser}>
              <div className="form-group col-md-12">
                <label htmlFor="formCode">
                  who invited you؟ (Enter the referral code for the friend who
                  invited you)
                </label>
                <input
                  value={code}
                  type="text"
                  className="form-control"
                  id="formCode"
                  onChange={(e) => setCodee(e.target.value)}
                  required
                />
              </div>

              <div className="justify-content-between d-flex form-group col-md-12">

              <button
                onClick={()=>BackStep()}
                  type="button"
                  disabled={isLoading}
                  className={`btn btn-primary btn-login back_btn btn-md  btn-loading ${
                    isLoading ? "is-loding" : ""
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader size={24} color={"#fff"} loading={true} />
                  ) : (
                    "Back"
                  )}
                </button>

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`btn btn-primary btn-login login_button btn-md  btn-loading ${
                    isLoading ? "is-loding" : ""
                  }`}
                >
                  {isLoading ? (
                    <ClipLoader size={24} color={"#fff"} loading={true} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </form>
          )}
          <div className="login-lower text-center">
            <div className="links">
              <Link className="link_footer"> Terms </Link>|
              <Link className="link_footer"> Privacy </Link>
            </div>
            <p> Copyright © 2021 | ICBS </p>
          </div>
        </div>
      </div>
      <div
        className="image_container col-md-5 col-12"
        style={{
          backgroundImage: `url(assets/images/pexels-fauxels-3184287.jpg)`,
        }}
      >
        <div className="overlay">
          <Link
            onClick={() => (window.location.href = "/")}
            className="btn-back"
          >
            <img src="assets/images/close.svg" alt="close" />
          </Link>
          <div className="logo_data">
            <a class="navbar-brand main_logo" >
              <img src="assets/images/logo.png" alt="LOGO" />
              <h3>ICBS</h3>
            </a>
            <p>Here is you will find your magical future</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state, { authenticate })(Registration);
