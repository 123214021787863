

import AuthReducer from './AuthReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    Auth: AuthReducer,
});

export default rootReducer;

