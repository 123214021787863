import React, { useState, useEffect } from "react";
const Description = () => {
  return (
    <div>
      <section className="page">
        <div class="container">
          <div class="row flex-md-row-reverse">
            <div class="col-md-12">
              <div class="about_right section_head">
                <h2 className="title">
                <span>refund &  </span> cancellation <span>policy</span>
                </h2>
                <p><span className="ICBS_span">ICBS </span> company don’t provide any option for refunding or cancellation policy for students on school section.
                </p>
                <p><span className="ICBS_span">ICBS </span> company only refund students if the buying option put wrongly by the company to appear to you otherwise there is no refund so be careful during navigating through any option for applying as school student or after logging in the school website. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  About Part End  */}
    </div>
  );
};
export default Description;
