
import React, {  useEffect } from "react";
import Navbar from './navbar'
import Footer from './footer'

export default ({ children }) => {
    useEffect(() => {
       if(window.location.pathname !='/'){
            if(sessionStorage.getItem('payment_msg')){
                sessionStorage.removeItem('payment_msg')
            }
        }
      }, []);

   return (
        <div  >
            <Navbar />
           <div className="view-page">
                {children}
            </div>
            <Footer />
        </div>
    )
}

